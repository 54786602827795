@import "../../scss/settings.scss";
.btn-primary{
    margin-left: 5px;
}

#buttonShow{
    //font-size: 2.75em;    
    min-height: calc(1em + .85rem + 2px);
    //padding: 0.5rem 1rem;
    font-size: 2rem;
    color:$icon-color;   
    background-color: transparent;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius; 
    border-left-color: transparent;
    border-top-color: $input-border-color;
    border-right-color: $input-border-color;        
    border-width: 1px;
    border-style: solid;
    border-bottom-color: $input-border-color;   
    box-shadow: none;
    outline: none
}



.password{     
    border-right-color: transparent;
}

.top-padding{
    padding-top: 5px;
}

.button-padding{
    padding-top: 20px;
}
.small-select{
    width: 10em
}

.la-lg{
    font-size: 1.25em;    
}

.las{
    cursor: pointer;
    color:$icon-color;
}

@import "../../scss/settings.scss";

.root {
  position: relative;  
}

.componentVisible{
  width: "200vw";
  height: "200vh";
  background: "transparent";
  position: "fixed";
  z-index: 0;
  top: 0;
  left: 0;
}

.baseButtonMixin {
  background: none;
  border: none;
  padding: 0;
}

.valueWrapper {
  // width: 100%;
  // padding-left: 8px;
  // padding-right: 32px;
  // height: 32px;
  // box-sizing: border-box;
  // border-radius: 1px;
  // border: 1px solid #b6c1ce;
  // line-height: 32px;
  transition: border-color 150ms linear;

  &:focus {
    border-color: #0063cc;
    outline: none;
  }
}

.autoCompleteContainer {
  background: #fff;
  padding: 8px 0;
  list-style-type: none;
  min-width: 320px;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #b6c1ce;
  border-radius: 2px;
  margin: 0;
  box-sizing: border-box;
  max-height: 280px;
  overflow-y: auto;
  z-index: 1;
}

.autoCompleteItem {
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #ebf4ff;
  }
}

.autoHighlight {
  font-weight: bold;
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  line-height: 32px;
  text-align: left;

  &:active {
    outline: none;
    color: #0076f5;
  }
}

.autoCompleteItemButton {
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  line-height: 32px;
  text-align: left;

  &:active {
    outline: none;
    color: #0076f5;
  }
}
