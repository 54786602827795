//@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
//@import url("https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css");
@import '../../scss/settings.scss';

.copy-span{
    padding:0 0 0 5px;  
    flex: auto;  
    display: flex;
}

.button-copy{
    color:#777;   
    background-color: transparent;
    border-radius: $input-border-radius;
    border-color: $input-border-color;
    border-style: solid;
    font-family: $font-family-regular;    
}

.copy-container {
    flex-direction: row;
    justify-content: flex-start;  
    display: flex;  
    min-height: 10px;
}

.copy-result{
    padding: 5px;
}