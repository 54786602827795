@import "../../scss/settings.scss";
.img-header {    
    background-size: cover;
    background-position-y: center;
    background-position-x: center;
    padding: 5px 0; //margin-top: -20px;
    margin-bottom: 20px;
    overflow: hidden;
    h1 {
        padding: 10px 10px 10px 10px;
        margin: 0 0 0 -10px; //text-shadow: 0 0 10px black, 0 0 5px black; //background-color: rgba(0, 0, 0, 0.3);
        display: block;
        @include respond-to(small) {
            font-size: 2rem;
        }
    };
    
}

.gen-layout {
    //min-height: calc(100vh - 56px);  
    flex:1;  
    padding-bottom: 30px; //background-color: #e7eef3;
    @include respond-to(medium) {
        padding-bottom: 10px;
    }
    @include respond-to(small) {
        margin-top: 10px;
    }
    .spacer {
        height: 20px;
    }
}