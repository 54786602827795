@use "sass:math";
@import 'src/scss/settings.scss';

// @import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

$header-height: 60px;
$nav-height: 8px;

.gen-header {
    height: $header-height + $nav-height;
    color: $site-primary-text;
    line-height: $header-height;
    margin-bottom: 10px;
    font-size: 16px;    
    background-color: $site-primary;
    //box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.67);

    
    @include respond-to(medium) {
        margin-bottom: 10px;
        height: 80px;
        line-height: 80px;
    }
    @include respond-to(small) {
        margin-bottom: 0px;
        height: 60px;
        line-height: 60px;
    }
    &.fixed {
        position: relative;
        top: 0;
    }
    .header-container {
        display: flex;        
        .site,
        a {
            display: flex;
            flex-direction: row;
            color: #fff;
            text-decoration: none;                   
            img {
                display: block; 
                align-self: center;
                height: $header-height * 0.7;
                line-height: $header-height * 0.7;
                transform-origin: center left; // margin-right: 130px;
                margin-right: 33px;
                @include respond-to(medium) {
                    height: 70px * 0.7
                }
                @include respond-to(small) {
                    height: 60px * 0.7
                }

                /* IE11 hack */
                _:-ms-fullscreen, :root & {
                    width: 50%
                }
            }
            .icon {
                display:inline-block;
                padding-right: 5px;
            }
            .tagline {
            
                font-size: 18px;
                @include respond-to(medium) {
                    font-size: 16px;
                    margin-left: 18px;
                }
                @include respond-to(small) {
                    font-size: 16px;
                    margin-left: 16px;
                }
            }
        }
        .options {
            display: block;
            padding: 0;
            margin: 0;
            margin-left: auto;
            white-space: nowrap;
            font-size: 18px;
            li {
                padding: 0;
                margin: 0;
                list-style: none;
                a {
                    display: block;
                    height: 100%;
                    padding-left: 0 math.div($header-height, 4);
                    text-decoration: none; 
                }
                .option-text {
                    @include respond-to(medium) {
                        display: none;
                    }
                }
                .option-icon {
                    display: none;
                    font-size: 28px;
                    
                    @include respond-to(medium) {
                        display: inline;
                    }
                }
            }
        }
    }
    &.fixed {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        &+div {
            margin-top: $header-height + $nav-height + 20;
        }
    }
}




.navbar {
    padding-top: 0;  
    position: relative;
    top: 0;  
    background-color: $site-primary;           
    z-index: 10;

    .navbar-toggler{
        background-color: $site-primary;
        border-radius: 0;
        border: 0; 
        outline: none;
        box-shadow: none; 
        
    }   
    .collapsed{
        height: 60px;
    } 
    .navbar-nav {
        padding-top: 0px;
        font-size: smaller;
    }     
    
    .navbar-toggler-icon {        
        filter: grayscale(1) invert(1);
        &focus{
            outline: none;
            box-shadow: none;
        }               
    }
    
    .navbar-brand {
        padding-top: 0;
    }
    .navbar-nav {        
        background: $site-primary;                      
        .nav-link {
            height: 41px;
            padding-top: 0;
            padding-right: 5px;
            padding-bottom: 62px;
            background: $site-primary;             
        }
    }
       
}